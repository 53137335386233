import styles from './button.module.css';

import classNames from 'classnames';
import { MouseEvent, ReactElement } from 'react';

import { Spinner, SpinnerColors } from 'components/common/spinner/spinner';

type Variant =
  | 'blue'
  | 'text'
  | 'redtext'
  | 'textWithArrow'
  | 'outline'
  | 'smoothBlue'
  | 'smoothRed'
  | 'ghostWithArrow';
export type Size = 'xs' | 'sm' | 'md' | 'lg';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string | ReactElement;
  icon?: ReactElement;
  className?: string;
  variant?: Variant;
  size?: Size;
  block?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (evt: MouseEvent<HTMLButtonElement>) => void;
}

export const Button = (props: ButtonProps) => {
  const {
    text,
    icon,
    className,
    variant = 'blue',
    size = 'lg',
    block = false,
    isLoading = false,
    onClick,
    ...otherProps
  } = props;

  const classes = classNames(styles.root, className, styles[variant], styles[size], { [styles.block]: block });

  let spinnerColor = SpinnerColors.white;
  if (variant === 'smoothRed') {
    spinnerColor = SpinnerColors.red;
  } else if (variant === 'smoothBlue') {
    spinnerColor = SpinnerColors.blue;
  }

  return (
    <button
      className={classes}
      onClick={onClick}
      disabled={props.disabled}
      type={props.type}
      data-testid='button'
      {...otherProps}
    >
      {icon && !isLoading && (
        // Для корректного цвета нужно убедиться, что переданная иконка
        // окрашивается с помощью свойства "fill" у элемента svg > path
        <div className={styles.icon}>{icon}</div>
      )}
      {isLoading && <Spinner classname={styles.spinner} size={24} color={spinnerColor} />}
      {text}
    </button>
  );
};
