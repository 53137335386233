import styles from './modal.module.css';

import CloseIcon from './icons/close.svg';

import classNames from 'classnames';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useLockBodyScroll } from 'hooks/scroll/use-lock-body-scroll';
import { useOnClickEscape } from 'hooks/use-on-click-escape';
import { useOnClickOutside } from 'hooks/use-on-click-outside';

import { Button } from 'components/common/button/button';
import { Text, TextColors } from 'components/common/text/text';

export function useModal(defaultOpened = false) {
  const [opened, toggleOpened] = useState(defaultOpened);
  const { setLocked } = useLockBodyScroll(defaultOpened);

  const onOpen = useCallback(() => {
    setTimeout(() => {
      toggleOpened(true);
      setLocked(true);
    }, 0);
  }, [opened]);

  const onClose = useCallback(() => {
    toggleOpened(false);
    setLocked(false);
  }, [opened]);

  return {
    opened,
    onOpen,
    onClose
  };
}

interface ModalProps {
  children: ReactElement;
  title: string | ReactElement;
  className?: string;
  footerClassName?: string;
  headerClassName?: string;
  headerWrapperClassName?: string;
  opened?: boolean;
  hasHeader?: boolean;
  hasFooter?: boolean;
  onClose: () => void;
  onApply?: () => void;
  headerChildren?: ReactElement;
  headerChildrenClassName?: string;
  footerChildren?: ReactElement;
  isBtnLoading?: boolean;
  isBtnDisabled?: boolean;
  btnClassName?: string;
  errorText?: string;
  btnText?: string;
}

export const Modal = (props: ModalProps) => {
  const { opened = false, hasFooter = false, hasHeader = true } = props;
  const [isRendered, setIsRendered] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(modalRef, opened, props.onClose);
  useOnClickEscape(props.onClose);
  useLockBodyScroll();

  useEffect(() => {
    if (opened) {
      setIsRendered(true);
    } else {
      setIsRendered(false);
    }
    return () => {
      setIsRendered(false);
    };
  }, [opened]);

  const modalClasses = classNames(
    styles.root,
    props.className,
    { [styles.rendered]: isRendered },
    { [styles.withoutFooter]: !hasFooter }
  );

  const node = typeof window === 'object' && window.document.getElementById('modal-root');
  if (!node) {
    return null;
  }

  const handleClick = () => {
    if (!props.isBtnLoading && props.onApply) {
      props.onApply();
    }
  };

  if (opened) {
    return createPortal(
      <>
        <div className={classNames(styles.overlay, { [styles.opened]: opened })}>
          <div className={modalClasses} ref={modalRef}>
            {hasHeader && (
              <div className={classNames(styles.header, props.headerClassName)}>
                <div className={classNames(styles.headerWrapepr, props.headerWrapperClassName)}>
                  <div className={styles.title}>{props.title}</div>
                  <button className={styles.close} onClick={props.onClose}>
                    <CloseIcon />
                  </button>
                </div>
                {props.headerChildren && (
                  <div className={classNames(styles.headerChildren, props.headerChildrenClassName)}>
                    {props.headerChildren}
                  </div>
                )}
              </div>
            )}

            <div className={styles.content}>{props.children}</div>
            {hasFooter && (
              <div className={classNames(styles.footer, props.footerClassName)}>
                {props.footerChildren && <div className={styles.footerChildren}>{props.footerChildren}</div>}
                <div className={classNames(styles.footerWrapper, props.btnClassName)}>
                  {!!props.errorText && (
                    <Text As='p' className={styles.errorText} size={14} color={TextColors.error}>
                      {props.errorText}
                    </Text>
                  )}
                  <Button
                    className={classNames(styles.submit, props.btnClassName)}
                    text={props.btnText ? props.btnText : 'Применить'}
                    disabled={props.isBtnDisabled}
                    isLoading={props.isBtnLoading}
                    onClick={handleClick}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>,
      node
    );
  }

  return null;
};
